import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone,faPlayCircle,  faPlusCircle, faEnvelope, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={true} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Erstellung Ihrer neuen Webseite"></SEO>
        <div className="loosepage text-center">
          <h1>Einfache Webseite</h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>Innovativ und kosteneffektiv. <span className="muted">Wir bieten benutzerfreundliche, maßgeschneiderte, schlüsselfertige Lösungen, die für kleine Unternehmen erschwinglich sind.</span></p>
        </div>
        <div style={{ margin: "auto", maxWidth: "800px" }}>
            <Img style={{ borderRadius: "7px" }}                     src={require("../../images/services/website2.jpg")}/>
        </div>
        <div className="text-center" style={{margin:20}}>
          <Link className="btn btn-primary-outline" to="/portfolio_b" target="_blank"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> Beispiel Webseiten</Link>
          <Link className="btn btn-primary-outline" to="/mehr-erfahren">Mehr erfahren <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>
        <br/>
        <div className="okbox">
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> <Link to="/gogreen" className="text-lime">Grünes Projekt mit Ökostrom ›</Link> </div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> <Link className="text-lime" to="/services/careplus">WordPress Care<FontAwesomeIcon style={{marginLeft:3}} icon={faPlusCircle}></FontAwesomeIcon></Link></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Selbstadministration mit WordPress</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Individuelles Theme</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Modernes Kontaktformular</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Seiten-Registrierung bei Google</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Suchmaschinenoptimierung für Google</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Newsletter/Kalender/Anmeldesystem</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Entwicklung WordPress - Erweiterungen</div>
        </div>
        <br />
        <br />
        <br />
      </Layout >
    )
  }
}


